import React from 'react';
import { Routes, Route } from "react-router-dom"
import { Layout } from './pages/layout';
import { Index } from './pages';
import Terms from './pages/terms';
// import './App.css';
import './assets/icons.min.css';
import About from './pages/about-us';
import Faqs from './pages/faqs';
import Contact from './pages/contact';
import Randevu from './pages/randevu';
import Login from './pages/login';
import Register from './pages/register';
import BasvuruTakibi from './pages/basvuru-takibi';


import AileVizesi from './pages/subpages/aile-vizesi';
import CalismaVizesi from './pages/subpages/calisma-vizesi';
import TicariVize from './pages/subpages/ticari-vize';
import TransitVize from './pages/subpages/transit-vize';
import TuristikVize from './pages/subpages/turistik-vize';

import 'animate.css';
import "animate.css/animate.compat.css"

function App() {

  return (
    <>
    <Routes>  
      <Route path='/' element={<Layout />} >
        <Route index={true} element={<Index />} />
       <Route path='terms' element={<Terms />} /> 
       <Route path='about-us' element={<About />} />
       <Route path='faqs' element={<Faqs />} /> 
       <Route path='contact' element={<Contact />} /> 
       <Route path='login' element={<Login />} /> 
       <Route path='register' element={<Register />} /> 
       <Route path='randevu' element={<Randevu />} /> 
       <Route path='aile-vizesi' element={<AileVizesi />} /> 
       <Route path='calisma-vizesi' element={<CalismaVizesi />} /> 
       <Route path='ticari-vize' element={<TicariVize />} />
       <Route path='transit-vize' element={<TransitVize />} /> 
       <Route path='turistik-vize' element={<TuristikVize />} /> 
       <Route path='basvuru-takibi' element={<BasvuruTakibi />} /> 
      </Route>
    </Routes> 
    </>
  );    
}   
export default App;
