import React from 'react'

export default function RandevuFormu() {
  return (
    <>
      <form className='app-form' id='appForm'>
        <div className='form-type mb-4'>

          <div className='form-box'>
            <h2><span className='step'>01</span>Vize Tipi Seçin:</h2>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="type01" value="option1" defaultChecked={true}></input>
              <label className="form-check-label" htmlFor="type01">Ticari</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="type02" value="option2"></input>
              <label className="form-check-label" htmlFor="type02">Turistik</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="type03" value="option3"></input>
              <label className="form-check-label" htmlFor="type03">Aile</label>
            </div>

            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="type04" value="option4"></input>
              <label className="form-check-label" htmlFor="type04">Çalışma</label>
            </div>

            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="type05" value="option5"></input>
              <label className="form-check-label" htmlFor="type05">Transit</label>
            </div>
          </div>
        </div>


        <div className='form-box mb-4'>
          <h2><span className='step'>02</span>Kimlik Bilgileri:</h2>
          <div className='row'>

            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Ad Soyad</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Uyruk</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Vatandaşlık</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Kimlik Numarası</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Gençlik Soyadı</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Doğum Tarihi</label>
              <input type="date" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Doğum Yeri (Ülke)</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Doğum Yeri (Şehir)</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Anne Adı</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Baba Adı</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Cinsiyet</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Medeni Durum</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
          </div>
        </div>

        <div className='form-box mb-4'>
          <h2><span className='step'>03</span>Pasaport Bilgileri:</h2>
          <div className='row'>

            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Pasaport Cinsi</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Pasaport Numarası</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Düzenlenme Tarihi</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-3 mb-3'>
              <label htmlFor="fullname" className="form-label">Bitiş Tarihi</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>

          </div>
        </div>

        <div className='form-box mb-4'>
          <h2><span className='step'>04</span>Erişim Bilgileri:</h2>
          <div className='row'>

            <div className='col-lg-4 mb-3'>
              <label htmlFor="fullname" className="form-label">Telefon Numarası</label>
              <input type="number" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor="fullname" className="form-label">Talep Süresi (Gün)</label>
              <input type="number" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor="fullname" className="form-label">Gidiş Nedeni</label>
              <input type="fullname" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-12 mb-3'>
              <label htmlFor="fullname" className="form-label">Kalacağınız Adres</label>
              <textarea className="form-control" id="fullname" placeholder="" rows="3"></textarea>
            </div>

          </div>
        </div>

        <div className='form-box mb-4'>
          <h2><span className='step'>05</span>Geçmiş Vize Bilgisi:</h2>
          <div className='row'>

            <div className='col-lg-4 mb-3'>
              <label htmlFor="fullname" className="form-label">Vize Sayısı</label>
              <input type="number" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor="fullname" className="form-label">Kaldığı Süre (Gün)</label>
              <input type="number" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-4 mb-3'>
              <label htmlFor="fullname" className="form-label">Tarih</label>
              <input type="date" className="form-control" id="fullname" placeholder=""></input>
            </div>
            <div className='col-lg-12 mb-3'>
              <label htmlFor="fullname" className="form-label">Kalınan Adres</label>
              <textarea className="form-control" id="fullname" placeholder="" rows="3"></textarea>
            </div>

          </div>
        </div>
      </form>
    </>
  )
}
