import React from 'react'

export default function ContactSection() {
  return (
    <section className='contact-section'>
    <div className='container'>
        <div className='row'>
            <div className='col-lg-12'>
                <p>Cezayir Vize
                </p>
                <h2 className='mb-5'><span>Başvuru</span> Formu
                </h2>
                <a className='button-default' href='/randevu'>Randevu Formu</a>
            </div>
        </div>
    </div>
</section>
  )
}
