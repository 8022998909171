import React from 'react'
import ContactSection from '../components/contact-section';
import { Helmet } from 'react-helmet';


export default function faqs() {
    return (
        <>
         <Helmet>
        <title>Be Partners İletişim</title>
        <meta name="keywords" content="Be Partners Sık Sorulanlar" />
      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Sık Sorulanlar</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Sık Sorulanlar</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section id='faqs' className='faqs-section py-5'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-12'>
                            <div className="accordion accordion-flush accordion-faqs" id="accordion-faqs">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-1" aria-expanded="true" aria-controls="flush-collapseOne">
                                           Vize Süreci Hakkında Soru 01
                                        </button>
                                    </h2>
                                    <div id="flush-1" className="accordion-collapse collapse show" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        Cezayir, Kuzey Afrika'da yer alan büyük bir ülkedir ve tarihi, kültürel zenginlikleriyle dikkat çeker. İşte Cezayir hakkında genel bilgiler: Cezayir, kuzeyde Akdeniz'e kıyısı olan bir ülkedir. Sahra Çölü'nün güneyinde geniş bir araziye sahiptir. Başkenti, Akdeniz kıyısındaki Cezayir şehridir.
 Cezayir, büyük bir nüfusa sahip bir ülkedir. Çeşitli etnik gruplardan oluşan nüfusun çoğunluğunu Araplar ve Berberiler oluşturur. Resmi dil Arapça ve Berberice'dir.
Cezayir, zengin tarihi ve kültürel mirasıyla öne çıkar. Antik Roma dönemine uzanan zengin arkeolojik kalıntılar, ülkenin tarihini yansıtır. Cezayir, Osmanlı İmparatorluğu ve Fransız sömürgeciliği dönemlerini de içeren karmaşık bir tarih geçmişine sahiptir.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-2" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Vize Süreci Hakkında Soru 02



                                        </button>
                                    </h2>
                                    <div id="flush-2" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        Cezayir, Kuzey Afrika'da yer alan büyük bir ülkedir ve tarihi, kültürel zenginlikleriyle dikkat çeker. İşte Cezayir hakkında genel bilgiler: Cezayir, kuzeyde Akdeniz'e kıyısı olan bir ülkedir. Sahra Çölü'nün güneyinde geniş bir araziye sahiptir. Başkenti, Akdeniz kıyısındaki Cezayir şehridir.
 Cezayir, büyük bir nüfusa sahip bir ülkedir. Çeşitli etnik gruplardan oluşan nüfusun çoğunluğunu Araplar ve Berberiler oluşturur. Resmi dil Arapça ve Berberice'dir.
Cezayir, zengin tarihi ve kültürel mirasıyla öne çıkar. Antik Roma dönemine uzanan zengin arkeolojik kalıntılar, ülkenin tarihini yansıtır. Cezayir, Osmanlı İmparatorluğu ve Fransız sömürgeciliği dönemlerini de içeren karmaşık bir tarih geçmişine sahiptir.

                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-3" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Vize Süreci Hakkında Soru 03
                                        </button>
                                    </h2>
                                    <div id="flush-3" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        Cezayir, Kuzey Afrika'da yer alan büyük bir ülkedir ve tarihi, kültürel zenginlikleriyle dikkat çeker. İşte Cezayir hakkında genel bilgiler: Cezayir, kuzeyde Akdeniz'e kıyısı olan bir ülkedir. Sahra Çölü'nün güneyinde geniş bir araziye sahiptir. Başkenti, Akdeniz kıyısındaki Cezayir şehridir.
 Cezayir, büyük bir nüfusa sahip bir ülkedir. Çeşitli etnik gruplardan oluşan nüfusun çoğunluğunu Araplar ve Berberiler oluşturur. Resmi dil Arapça ve Berberice'dir.
Cezayir, zengin tarihi ve kültürel mirasıyla öne çıkar. Antik Roma dönemine uzanan zengin arkeolojik kalıntılar, ülkenin tarihini yansıtır. Cezayir, Osmanlı İmparatorluğu ve Fransız sömürgeciliği dönemlerini de içeren karmaşık bir tarih geçmişine sahiptir.

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-4" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Vize Süreci Hakkında Soru 04
                                        </button>
                                    </h2>
                                    <div id="flush-4" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        Cezayir, Kuzey Afrika'da yer alan büyük bir ülkedir ve tarihi, kültürel zenginlikleriyle dikkat çeker. İşte Cezayir hakkında genel bilgiler: Cezayir, kuzeyde Akdeniz'e kıyısı olan bir ülkedir. Sahra Çölü'nün güneyinde geniş bir araziye sahiptir. Başkenti, Akdeniz kıyısındaki Cezayir şehridir.
 Cezayir, büyük bir nüfusa sahip bir ülkedir. Çeşitli etnik gruplardan oluşan nüfusun çoğunluğunu Araplar ve Berberiler oluşturur. Resmi dil Arapça ve Berberice'dir.
Cezayir, zengin tarihi ve kültürel mirasıyla öne çıkar. Antik Roma dönemine uzanan zengin arkeolojik kalıntılar, ülkenin tarihini yansıtır. Cezayir, Osmanlı İmparatorluğu ve Fransız sömürgeciliği dönemlerini de içeren karmaşık bir tarih geçmişine sahiptir.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-5" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Vize Süreci Hakkında Soru 05
                                        </button>
                                    </h2>
                                    <div id="flush-5" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                        Cezayir, Kuzey Afrika'da yer alan büyük bir ülkedir ve tarihi, kültürel zenginlikleriyle dikkat çeker. İşte Cezayir hakkında genel bilgiler: Cezayir, kuzeyde Akdeniz'e kıyısı olan bir ülkedir. Sahra Çölü'nün güneyinde geniş bir araziye sahiptir. Başkenti, Akdeniz kıyısındaki Cezayir şehridir.
 Cezayir, büyük bir nüfusa sahip bir ülkedir. Çeşitli etnik gruplardan oluşan nüfusun çoğunluğunu Araplar ve Berberiler oluşturur. Resmi dil Arapça ve Berberice'dir.
Cezayir, zengin tarihi ve kültürel mirasıyla öne çıkar. Antik Roma dönemine uzanan zengin arkeolojik kalıntılar, ülkenin tarihini yansıtır. Cezayir, Osmanlı İmparatorluğu ve Fransız sömürgeciliği dönemlerini de içeren karmaşık bir tarih geçmişine sahiptir.
                                        </div>                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <ContactSection></ContactSection>
        </>
    )
}
