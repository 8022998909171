import React from 'react'



import banner2 from '../assets/banner-2.webp';

function Login(props) {
    return (
        <>

            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Üye Girişi</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Üye Girişi</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                        <div className='form-box mb-4'>
                            {/* <h5>Kayıtlı Üyeler</h5> */}
                            <h2>Giriş Yap</h2>

                            <form className='user-form'>
                                <div className='col-lg-12 mb-3'>
                                    <label htmlFor="email" className="form-label">E Posta Adresiniz</label>
                                    <input type="email" className="form-control" id="email" placeholder=""></input>
                                </div>
                    

                      
                                <div className='col-lg-12 mb-3'>
                                    <label htmlFor="password" className="form-label">Şifre</label>
                                    <input type="password" className="form-control" id="password" placeholder=""></input>
                                </div>

                                
                                <button className='button-default mt-4 w-100' target='_blank' title='Giriş Yap'>
                                    Giriş Yap
                                </button>

                                
                                <a className='button-link mt-3 d-block' target='_blank' href='/reset-password' rel='noreferrer' title='Şifremi Unuttum'>
                                <i className="bi bi-question-circle"></i> Şifremi Unuttum
                                </a>
                                </form>
                     


                                </div>

                        </div>
                        <div className='col-lg-5 d-none'>
                            <img className='img-fluid radius' src={banner2} title='Banner1' alt='Banner1'></img>
                        </div>

                    </div>

                </div>


            </section>



        </>
    )
}

export default Login
