import React from 'react'
import RandevuFormu from '../components/randevu-formu';
import ContactSection from '../components/contact-section';


export default function Randevu() {
  return (
   <>
               <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Randevu Formu</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Randevu Al</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section py-6'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <RandevuFormu></RandevuFormu>
        </div>
        </div>
    </div>
</section>

            <ContactSection></ContactSection>
   </>
  )
}
