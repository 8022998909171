import React, { useState } from 'react';


function Form() {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name, website, message }),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        setError('Gönderim hatası: ' + response.statusText);
      }
    } catch (error) {
      setError('Gönderim hatası: ' + error.toString());
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
    <div className="App">
      {submitted ? (
        <div>Teşekkürler! Email'iniz alındı.</div>
      ) : (
        <form onSubmit={handleSubmit}>

<div className='row'>
<div className='col-lg-6 mb-3'>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleNameChange}
              placeholder='Ad Soyad *'
                className='form-control'
              required
            />
          </div>

          <div className='col-lg-6 mb-3'>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder='E Posta *'
                className='form-control'
              required
            />
          </div>

          <div className='col-lg-12 mb-3'>
            <input
              type="text"
              id="website"
              name="website"
              value={website}
              onChange={handleWebsiteChange}
              placeholder='Telefon *'
              className='form-control'
              required
            />
          </div>
          <div className='col-lg-12 mb-3'>
            <textarea
             id="message"
             name="message"
             rows="8"             
             value={message}
             onChange={handleMessageChange}
             placeholder='Mesajınız *'
             className='form-control'
             required
            >

            </textarea>
          </div>
</div>
          <button type="submit" className='button-default mt-4'>Mesajı Gönder</button>
        </form>
      )}
      {error && <div>{error}</div>}
    </div>


    </>



  );    
}   



export default Form;