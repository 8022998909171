import React from 'react'
import Form from '../components/form'
import ContactSection from '../components/contact-section';
import { Helmet } from 'react-helmet';

function BasvuruTakibi(props) {
    return (
        <>
            <Helmet>
            <title>Be Partners Başvuru Takibi</title>
                <meta name="keywords" content="Be Partners Başvuru Takibi" />

            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Başvuru Takibi</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Başvuru Takibi</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>
                                Süreç Takibi
                            </h5>
                            <h2>Başvurularınız</h2>

                            <div className='table-responsive w-100'>
                            <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Tarih</th>
      <th scope="col">Ad Soyad</th>
      <th scope="col">Vize Tipi</th>
      <th scope="col">Durum</th>
      <th scope="col">Detaylar</th>
    </tr>
  </thead>
  <tbody>
    <tr className='align-middle'>
      <th scope="row">1</th>
      <td>18.08.2024</td>
      <td>Ömer DALKIRAN</td>
      <td>Çalışma Vizesi</td>
      <td><span class="badge text-bg-success">Onaylandı</span></td>
      <td><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#appDetailsModal">Detaylar</button></td>
    </tr>
    <tr className='align-middle'>
      <th scope="row">2</th>
      <td>18.08.2024</td>
      <td>Ömer DALKIRAN</td>
      <td>Çalışma Vizesi</td>
      <td><span class="badge text-bg-warning">Eksik Evrak</span></td>
      <td><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#appDetailsModal">Detaylar</button></td>
    </tr>
    <tr className='align-middle'>
      <th scope="row">3</th>
      <td>18.08.2024</td>
      <td>Ömer DALKIRAN</td>
      <td>Çalışma Vizesi</td>
      <td><span class="badge text-bg-danger">Reddedildi</span></td>
      <td><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#appDetailsModal">Detaylar</button></td>
    </tr>
  </tbody>
</table>
                            </div>


                        </div>
      

                    </div>

                </div>


<div class="modal fade" id="appDetailsModal" tabindex="-1" aria-labelledby="appDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="appDetailsModalLabel">Başvuru Detayları</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className='table-responsive w-100'>
                            <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Tarih</th>
      <th scope="col">Kimlik</th>
      <th scope="col">Pasaport</th>
      <th scope="col">Erişim</th>
      <th scope="col">Vize Geçmişi</th>
      <th scope="col">Ek Bilgiler</th>
    </tr>
  </thead>
  <tbody>
    <tr className='align-middle'>
      <th scope="row">18.08.2024</th>
      <td>Ömer DALKIRAN, Türk, Türkiye Cumhuriyeti, TC: 244822250, 10.08.1986, Ahmet, Ayşe, Kadın, Evli
      </td>
      <td>Yeşil, PS02825, 19.08.2024, 12.08.202</td>
      <td>0533 368 15 35, 8 gün, Turizm, Ulukavak mh. Milönü Cd. No:8/2 Ankara
      </td>
      <td>0533 368 15 35, 8 gün, Turizm, Ulukavak mh. Milönü Cd. No:8/2 Ankara
      </td>
      <td>-
      </td>

    </tr>
  
  </tbody>
</table>
                            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  data-bs-dismiss="modal" aria-label="Close">Kapat</button>
      </div>
    </div>
  </div>
</div>


            </section>

            <section className='form-section mb-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                                <h5>Sorunuz mu var?</h5>
                                <h2>Bize Yazın</h2>
                            </div>
                            <Form></Form>
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection></ContactSection>

        </>
    )
}

export default BasvuruTakibi
