import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [strokeOffset, setStrokeOffset] = useState(0);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const strokeVal = (window.pageYOffset / scrollHeight) * 360;
    setStrokeOffset(strokeVal);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && 
        <div className="scroll-button" onClick={scrollToTop}>
          <svg viewBox="0 0 100 100" className="progress-circle">
            <circle cx="50" cy="50" r="45" />
            <circle cx="50" cy="50" r="45" style={{ strokeDasharray: '282.6', strokeDashoffset: 282.6 - (282.6 * strokeOffset / 360) }} />
          </svg>


<i className="bi bi-arrow-up-short"></i>
        </div>
      }
    </div>
  );
};

export default ScrollToTopButton;
