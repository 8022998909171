import React from 'react'
// import Carousel from "react-multi-carousel";
import { useMediaQuery } from 'react-responsive'
// import ContactSection from '../components/contact-section';
// import BlogSlider from '../components/blog-slider';
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from 'react-animate-on-scroll';




const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
}


export function Index(props) {

    // const responsive = {
    //     superLargeDesktop: {
    //         // the naming can be any, depends on you.
    //         breakpoint: { max: 4000, min: 3000 },
    //         items: 5
    //     },
    //     desktop: {
    //         breakpoint: { max: 3000, min: 1024 },
    //         items: 3
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 2
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1
    //     }
    // };


    return (
        <>

            <section id='home' className='home'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>


                            <div className='welcome-screen'>
       
                                <h1 className=' animate__animated animate__fadeIn'>Cezayir Vize Başvuru Merkezi
                                </h1>
                                <p className='mb-5  animate__animated animate__fadeIn'>Cezayir vize başvuru sürecizini sizler için yönetiyor en kısa sürede sonuca ulaştırıyoruz.

                                </p>

                                <a className='button-default  animate__animated animate__fadeInUp' href='/randevu' rel='noreferrer' title='Contact via Telegram'>
                                    Randevu Al
                                </a>

                                <Mobile>
                                    {/* <button className='button-default' data-bs-toggle="modal" data-bs-target="#staticBackdrop" rel='noreferrer' title='Watch Promo'>
                                    <i className="fa-regular fa-circle-play"></i>  Watch Promo
                                </button> */}
                                </Mobile>



                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='main-blocks' >
                <div className='container'>
                    <div className='row g-0 row-shadow'>
                        <div className='col-lg-4'>
                            <div className='block-syle-01   animate__animated animate__fadeIn' >
                
                                <div className='block-icon'>
                                    <i className="fa-regular fa-chess-queen" aria-hidden="true"></i>
                                </div>
                                <h2>Gerekli Belgeler
                                </h2>
                                <p>Cezayir vize politikaları zaman zaman değişebilir. Güncel bilgileri bizlere ulaşarak edinebilirsiniz.

                                </p>
                     
                            </div>
                        </div>
                        <div className='col-lg-4'>
                        <div className='block-syle-01 gray-box   animate__animated animate__fadeIn' >
             
                                <div className='block-icon'>
                                    <i className="fa-regular fa-chess-queen" aria-hidden="true"></i>
                                </div>
                                <h2>Ön Başvuru
                                </h2>
                                <p>Cezayir vize politikaları zaman zaman değişebilir. Güncel bilgileri bizlere ulaşarak edinebilirsiniz.

                                </p>
       
                            </div>
                        </div>
                        <div className='col-lg-4'>
                        <div className='block-syle-01   animate__animated animate__fadeIn' >

                                <div className='block-icon'>
                                    <i className="fa-regular fa-chess-queen" aria-hidden="true"></i>
                                </div>
                                <h2>Onay Süreci
                                </h2>
                                <p>Cezayir vize politikaları zaman zaman değişebilir. Güncel bilgileri bizlere ulaşarak edinebilirsiniz.

                                </p>
  
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-02 pt-5 mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <h2>Cezayir Vize Süreçleri</h2>
                            </ScrollAnimation>


                        </div>
                        <div className='col-lg-7'>
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <p>
                                    Cezayir vize politikaları zaman zaman değişebilir. Güncel bilgileri bizlere ulaşarak edinebilirsiniz. Yapılacak tüm işlemler, gerekli evraklar ve sürece ilişkin detaylı bilgi için randevu oluşturarak sizinle iletişime geçmemizi sağlayabilirsiniz
                                    Hizmetlerimizi uluslararası standartlarda vermek ve kurumsal kimliğimizi günden güne geliştirmek adına çalışmalarımızı sürdürmekteyiz.
                                </p>
                            </ScrollAnimation>
                        </div>

                    </div>

                </div>


            </section>

            <section id='features' className='key-features-section py-6'>


                <div className='container'>
                    <div className="row justify-content-center items">
                        <div className='col-lg-12'>
                            <div className='feature-column mb-5'>
                                <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                                    <p className='sub-title'>HİZMETLERİMİZ</p>
                                    <h2>Vize Tipleri</h2>
                                </ScrollAnimation>

                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>

                                <div className="card help-card featured">
                                    <i className="fa-regular fa-chess-queen"></i>
                                    <a href='/ticari-vize' title='Ticari Vize'>
                                        <h4>Ticari Vize</h4>
                                    </a>
                                    <p>
                                    Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır. 
                                    </p>
                                    <div className='box-number'>
                                        01
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <div className="card help-card">
                                    <i className="fa-regular fa-lightbulb"></i>
                                    <a href='/turistik-vize' title='Turistik Vize'>
                                        <h4>Turistik Vize</h4>
                                    </a>
                                    <p>
                                    Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır. 
                                    </p>
                                    <div className='box-number'>
                                        02
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <div className="card help-card">
                                    <i className="fa-solid fa-code"></i>
                                    <a href='/aile-vizesi' title='Aile Vizesi'>
                                        <h4>Aile Vizesi</h4>
                                    </a>
                                    <p>
                                    Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır. 
                                    </p>
                                    <div className='box-number'>
                                        03
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>





                        <div className='col-12 col-md-6 col-lg-4 item'>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <div className="card help-card">
                                    <i className="fa-solid fa-film"></i>
                                    <a href='/calisma-vizesi' title='Çalışma Vizesi'>
                                        <h4>Çalışma Vizesi
                                        </h4>
                                    </a>
                                    <p>
                                    Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır. 
                                    </p>
                                    <div className='box-number'>
                                        04
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                <div className="card help-card">
                                    <i className="fa-brands fa-searchengin"></i>
                                    <a href='/transit-vize' title='Transit Vize'>
                                        <h4>Transit Vize</h4>
                                    </a>
                                    <p>
                                    Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır. 
                                    </p>
                                    <div className='box-number'>
                                        05
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <div className="card help-card">
                                    <i className="fa-solid fa-compass-drafting"></i>
                                    <a href='/vize-surec-takibi' title='Süreç Takibi'>
                                        <h4>Süreç Takibi</h4>
                                    </a>
                                    <p>
                                    Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır. 
                                    </p>
                                    <div className='box-number'>
                                        06
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>


                    </div>
                </div>


            </section>


    

     




        </>
    )
}
