import React from 'react'

function Terms(props) {
    return (
        <>
            dfggdfgdgdfg
        </>
    )
}

export default Terms
