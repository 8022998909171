import React from 'react'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { Outlet } from 'react-router-dom'

export function Layout() {
    

    return (
        <>
          <Header></Header>
        <main  data-bs-spy="scroll" data-bs-target="#main-nav" data-bs-smooth-scroll="true" tabIndex="0">

          <Outlet />
        </main>
        <Footer></Footer>
    </>
    )
}
