import React from 'react'
// import { NavLink } from "react-router-dom"
import logo from '../assets/logo.png';





export function Footer(props) {

    return (
        <>
             <footer>
             <a href='#home' className='logo text-center mx-auto d-block mb-5' title='Nova Crypto Marketing Agency'>  <img src={logo} title='Logo' alt='Nova Crypto Marketing Agency'></img></a>

             <div className="social-icons d-flex justify-content-center mb-4">
                                    <a className="twitter" rel='noreferrer' title='Twitter' href="/" target="_blank">
                                        <i className="icon-social-twitter"></i>
                                    </a>

                                    <a className="telegram" rel='noreferrer' title='Telegram' href="/" target="_blank">
                                    <i className="bi bi-send"></i>
                                    </a>
                                    <a className="whatsapp" rel='noreferrer' title='Whatsapp' href="/" target="_blank">
                                    <i className="bi bi-whatsapp"></i>
                                    </a>

                                    <a className="facebook" rel='noreferrer' title='Facebook' href="/" target="_blank">
                                        <i className="icon-social-facebook"></i>
                                    </a>
                                    <a className="linkedin" rel='noreferrer' title='Linkedin' href="/" target="_blank">
                                        <i className="icon-social-linkedin"></i>
                                    </a>
                                    <a className="email" rel='noreferrer' title='E-Mail' href="mailto:info@bepartners.com" target="_blank">
                                        <i className="icon-envelope-open"></i>
                                    </a>
                                </div>

                <div className='col-lg-6 mx-auto'>
                  <nav id="main-nav" className="main-menu navbar navbar-expand-lg">

                                        <div className="collapse navbar-collapse" id="navbarMain">
                         
                           
                                        <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a href="/"  className="nav-link">Anasayfa</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/about-us" className="nav-link">Hakkımızda</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/faqs"  className="nav-link">Sık Sorulanlar</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/contact"  className="nav-link">İletişim</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/kvkk"  className="nav-link">KVKK</a>
                                                </li>
                                            </ul>

                                        </div>

                                    </nav>

                </div>

                <div className='col-lg-8 mx-auto text-center'>
                    <p className='copyright'>©2022 Be Partners, Tüm Hakları Saklıdır.</p>
                </div>
            </footer>
        </>
    )
}
