import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo.png';
import ScrollToTopButton from './scroll';
import { useLocation } from 'react-router-dom';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1180 });
    return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1179 });
    return isMobile ? children : null;
};

export function Header(props) {
    const [scroll, setScroll] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const getClassName = (path, hasDropdown) => {
        return `nav-link ${hasDropdown ? 'dropdown-toggle' : ''} ${location.pathname === path ? 'active' : ''}`.trim();
    };

    const handleDropdownClick = (event) => {
        const target = event.currentTarget;
        if (target.getAttribute('href') !== '#') {
            // Navigate to the link
            window.location.href = target.getAttribute('href');
        }
    };

    return (
        <>
            <header>
                <div className={scroll ? "sticky sticky-fixed" : "sticky"}>
                    <div className='container'>
                        <div className='row g-0 align-items-center justify-content-between'>
                            <div className='col-lg-2 col-6'>
                                <a href='/' className='logo' title='Nova Crypto Marketing Agency'>
                                    <img src={logo} title='Nova Crypto Marketing Agency' alt='Nova Crypto Marketing Agency' />
                                </a>
                            </div>

                            <Desktop>
                                <div className='col-lg-6'>
                                    <nav id="main-nav" className="main-menu navbar navbar-expand-lg">
                                        <div className="collapse navbar-collapse" id="navbarMain">
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a href="/" className={getClassName('/', false)}>Anasayfa</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/about-us" className={getClassName('/about-us', false)}>Hakkımızda</a>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/ticari-vize" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleDropdownClick}>Vizeler</a>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="/ticari-vize">Ticari Vize</a></li>
                                                        <li><a className="dropdown-item" href="/turistik-vize">Turistik Vize</a></li>
                                                        <li><a className="dropdown-item" href="/aile-vizesi">Aile Vizesi</a></li>
                                                        <li><a className="dropdown-item" href="/calisma-vizesi">Çalışma Vizesi</a></li>
                                                        <li><a className="dropdown-item" href="/transit-vize">Transit Vize</a></li>
                                                    </ul>
                                                </li>

                                                <li className="nav-item">
                                                    <a href="/faqs" className={getClassName('/faqs', false)}>Sık Sorulanlar</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/contact" className={getClassName('/contact', false)}>İletişim</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </Desktop>

                            <Mobile>
                                <div className="offcanvas offcanvas-start sidebar" tabIndex="-1" id="sideBar" aria-labelledby="sidebarLabel">
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="sidebarLabel">Menu</h5>
                                        <button type="button" className="button-close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                                    </div>
                                    <div className="offcanvas-body">
                                        <nav id="main-nav" className="sidebar-menu navbar navbar-expand">
                                            <div className="collapse navbar-collapse" id="navbarMain">
                                                <ul className="navbar-nav">
                                                    <li className="nav-item">
                                                        <a href="/" className={getClassName('/', false)}>Anasayfa</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/about-us" className={getClassName('/about-us', false)}>Hakkımızda</a>
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                        <a href="/ticari-vize" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Vizeler</a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="/ticari-vize">Ticari Vize</a></li>
                                                            <li><a className="dropdown-item" href="/turistik-vize">Turistik Vize</a></li>
                                                            <li><a className="dropdown-item" href="/aile-vizesi">Aile Vizesi</a></li>
                                                            <li><a className="dropdown-item" href="/calisma-vizesi">Çalışma Vizesi</a></li>
                                                            <li><a className="dropdown-item" href="/transit-vize">Transit Vize</a></li>
                                                        </ul>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a href="/faqs" className={getClassName('/faqs', false)}>Sık Sorulanlar</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/contact" className={getClassName('/contact', false)}>İletişim</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </Mobile>

                            <div className='col-lg-3 col-6 d-flex justify-content-end align-items-center'>
                                <div className="dropdown lang-dropdown">
                                    <button className="dropdown-toggle  me-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="fi fi-gb"></span>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="/">  <span className="fi fi-tr"></span>Türkçe</a></li>
                                        <li><a className="dropdown-item" href="/">  <span className="fi fi-gb"></span>English</a></li>
                                        <li><a className="dropdown-item" href="/">  <span className="fi fi-sy"></span>عربي</a></li>
                                        <li><a className="dropdown-item" href="/">  <span className="fi fi-fr"></span>Français</a></li>
                                    </ul>
                                </div>
                                <Mobile>
                                    <button className="navbar-toggler" name="Sidebar" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar" aria-controls="sideBar">
                                        <i className="bi bi-text-left"></i>
                                    </button>
                                </Mobile>
                                <Desktop>

                                    <a href="/login" title='Hesabım' className="button-default">Hesabım</a>
                                </Desktop>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </header>
        </>
    );
}
