import React from 'react'
import Form from '../components/form'
import { Helmet } from 'react-helmet';



export default function Contact() {
  return (
   <>
                                               <Helmet>
        <title>Be Partners İletişim</title>
        <meta name="keywords" content="Be Partners İletişim, Be Partners telefon" />

      </Helmet>
               <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Bize Ulaşın</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>İletişim</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section id='contact' className='contact-page-section my-5 py-5'>
                <div className='container'>
                    <div className='row'>
   
                        <div className='col-lg-6 pe-lg-4'>
        <div className='content-style-01'>
    <h5>Be partners</h5>
    <h2>Bize Yazın</h2>
    </div>
        <Form></Form>
        </div>
        
        <div className='col-lg-6 ps-lg-4'>
                        <div className='content-style-01'>
    <h5>Haritada biz</h5>
    <h2>Bize Ulaşın</h2>
    </div>

    <iframe title='Harita' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3060.6601795213214!2d32.812172075985735!3d39.90423997152653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDU0JzE1LjMiTiAzMsKwNDgnNTMuMSJF!5e0!3m2!1str!2str!4v1723832208164!5m2!1str!2str" width="100%" height="400" CssStyle="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <p>Beştepe Mahallesi Dumlupınar Bulvarı Armada Ofis No: 6 B Blok Kat:11 No:15 Yenimahalle/ANKARA</p>
                    </div>
                    </div>
                </div>
            </section>

   </>
  )
}
