import React from 'react'
import Form from '../../components/form';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';

function TicariVize(props) {
    return (
        <>
            <Helmet>
                <title>Ticari Vize</title>
                <meta name="keywords" content="Cezayir Ticari Vize" />

            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Ticari Vize</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Ticari Vize</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h5>Başvuru Süreci</h5>
                            <h2>Ticari Vize Nedir?

                            </h2>
                            <p>
                                E Partners bir çok deneyimi bir araya getirmiş genç dinamik bir danışmanlık şirketidir.

                                Cezayir vize politikaları zaman zaman değişebilir. Güncel bilgileri bizlere ulaşarak edinebilirsiniz. Yapılacak tüm işlemler, gerekli evraklar ve sürece ilişkin detaylı bilgi için randevu oluşturarak sizinle iletişime geçmemizi sağlayabilirsiniz. Cezayir vize başvurularınızı en güvenilir şekilde yönetmek üzere BePartners ekibi olarak hizmetinizdeyiz. Yılların birikimiyle kazandığımız deneyim, size kaliteli ve profesyonel bir danışmanlık deneyimi sunmayı amaçlamaktadır. Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır.

                                Hizmetlerimizi uluslararası standartlarda vermek ve kurumsal kimliğimizi günden güne geliştirmek adına çalışmalarımızı sürdürmekteyiz.
                            </p>
                            <p>
                                Cezayir, Kuzey Afrika'da yer alan büyük bir ülkedir ve tarihi, kültürel zenginlikleriyle dikkat çeker. İşte Cezayir hakkında genel bilgiler:

                                Cezayir, kuzeyde Akdeniz'e kıyısı olan bir ülkedir. Sahra Çölü'nün güneyinde geniş bir araziye sahiptir. Başkenti, Akdeniz kıyısındaki Cezayir şehridir.

                                Cezayir, büyük bir nüfusa sahip bir ülkedir. Çeşitli etnik gruplardan oluşan nüfusun çoğunluğunu Araplar ve Berberiler oluşturur. Resmi dil Arapça ve Berberice'dir.

                                Cezayir, zengin tarihi ve kültürel mirasıyla öne çıkar. Antik Roma dönemine uzanan zengin arkeolojik kalıntılar, ülkenin tarihini yansıtır. Cezayir, Osmanlı İmparatorluğu ve Fransız sömürgeciliği dönemlerini de içeren karmaşık bir tarih geçmişine sahiptir.

                                Cezayir, çeşitli doğal güzelliklere ve tarihi sitelere ev sahipliği yapmaktadır. Tassili n'Ajjer Ulusal Parkı gibi doğal rezervler, Casbah of Algiers gibi tarihi bölgeler ve Akdeniz sahilindeki güzel plajlar, turistleri cezbetmektedir.

                                Cezayir, zengin tarihi, çeşitli kültürü ve etkileyici doğal güzellikleriyle keşfedilmeye değer bir ülkedir.
                            </p>

                        </div>




                    </div>

                </div>


            </section>

            <section className='form-section mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                                <h5>Sorunuz mu var?</h5>
                                <h2>Bize Yazın</h2>
                            </div>
                            <Form></Form>
                        </div>
                    </div>
                </div>
            </section>


            <ContactSection></ContactSection>
        </>
    )
}

export default TicariVize
