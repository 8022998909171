import React from 'react'
import Form from '../components/form'
import ContactSection from '../components/contact-section';
import { Helmet } from 'react-helmet';


import banner2 from '../assets/banner-2.webp';

function About(props) {
    return (
        <>
            <Helmet>
            <title>Be Partners Hakkında</title>
                <meta name="keywords" content="Be Partners Kurumsal" />

            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Be Partners</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Hakkımızda</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h5>Deneyimli Ekip</h5>
                            <h2>Biz Kimiz?</h2>
                            <p>
                                E Partners bir çok deneyimi bir araya getirmiş genç dinamik bir danışmanlık şirketidir.



                                Cezayir vize politikaları zaman zaman değişebilir. Güncel bilgileri bizlere ulaşarak edinebilirsiniz. Yapılacak tüm işlemler, gerekli evraklar ve sürece ilişkin detaylı bilgi için randevu oluşturarak sizinle iletişime geçmemizi sağlayabilirsiniz. 
                                
                                </p>
                                <p>

                                Cezayir vize başvurularınızı en güvenilir şekilde yönetmek üzere BePartners ekibi olarak hizmetinizdeyiz. Yılların birikimiyle kazandığımız deneyim, size kaliteli ve profesyonel bir danışmanlık deneyimi sunmayı amaçlamaktadır.
                            </p>
                            <p>
                                Uzman kadromuz, Cezayir vize başvuru sürecinizde her adımda yanınızda olup, başvurunuzun sorunsuz ve hızlı bir şekilde tamamlanmasını sağlamaktadır.



                                Hizmetlerimizi uluslararası standartlarda vermek ve kurumsal kimliğimizi günden güne geliştirmek adına çalışmalarımızı sürdürmekteyiz.

                            </p>


                            <div className='row mt-5'>
                                <div className='col-6'>
                                    <ul className="check-list mb-4">
                                        <li>
                                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                                            <div className="list-text">
                                                <p>Marketing Strategist</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                                            <div className="list-text">
                                                <p>Backend Developer</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                                            <div className="list-text">
                                                <p>Front End Developer</p>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                                <div className='col-6'>
                                    <ul className="check-list mb-4">
                                        <li>
                                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                                            <div className="list-text">
                                                <p>UI Designer
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                                            <div className="list-text">
                                                <p>Video Editor</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                                            <div className="list-text">
                                                <p>Social Media Expert</p>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <img className='img-fluid radius' src={banner2} title='Banner1' alt='Banner1'></img>
                        </div>

                    </div>

                </div>


            </section>

            <section className='form-section mb-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                                <h5>Sorunuz mu var?</h5>
                                <h2>Bize Yazın</h2>
                            </div>
                            <Form></Form>
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection></ContactSection>

        </>
    )
}

export default About
