import React from 'react'
import { Helmet } from 'react-helmet';


import banner2 from '../assets/banner-2.webp';

function Register(props) {
    return (
        <>
            <Helmet>
                <title>Kayıt Ol</title>
                <meta name="keywords" content="Kayıt Ol" />

            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Kayıt Ol</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Anasayfa</a></li>
                                <li>Kayıt Ol</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                        <div className='form-box mb-4'>
                            {/* <h5>Kayıtlı Üyeler</h5> */}
                            <h2>Üyelik</h2>

                            <div className='col-lg-12 mb-3'>
                                    <label htmlFor="fullname" className="form-label">Ad Soyad</label>
                                    <input type="text" className="form-control" id="fullname" placeholder=""></input>
                                </div>

                            <form className='user-form'>
                                <div className='col-lg-12 mb-3'>
                                    <label htmlFor="email" className="form-label">E Posta Adresiniz</label>
                                    <input type="email" className="form-control" id="email" placeholder=""></input>
                                </div>
                    

                                <button className='button-default mt-4 w-100' target='_blank' title='Kayıt Ol'>
                                   Kayıt Ol
                                </button>

                                
                                <a className='button-link mt-3 d-block' target='_blank' href='/reset-password' rel='noreferrer' title='Şifremi Unuttum'>
                                </a>
                                </form>
                     


                                </div>

                        </div>
                        <div className='col-lg-5 d-none'>
                            <img className='img-fluid radius' src={banner2} title='Banner1' alt='Banner1'></img>
                        </div>

                    </div>

                </div>


            </section>



        </>
    )
}

export default Register
